import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {};
    }

    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-5">
                            <a href="/" className="footer-logo">LOGO</a>
                        </div> 
                        <div className="col-sm-12 col-md-7">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 keep-nav">
                                    <h5>Continue navegando</h5>
                                    <a href="">Quem somos</a>
                                    <a href="">Informações aos pacientes</a>
                                    <a href="">Tipos de varizes</a>
                                    <a href="">Opções de tratamentos</a>
                                    <a href="">Perguntas frequentes</a>
                                </div>
                                <div className="col-sm-12 col-md-6 contact-us">
                                    <h5>Entre em contato</h5>
                                    <p>veincenter@veincenter.com</p>
                                    <ul>
                                        <li>(21) 99999-9999</li>
                                        <li>(21) 99999-9999</li>
                                    </ul>
                                    <button type="button" className="btn btn-email">Enviar uma mensagem</button>
                                    <ul className="social">
                                        <li>
                                            <button type="button" className="btn-icon"></button>
                                        </li>
                                        <li>
                                            <button type="button" className="btn-icon"></button>
                                        </li>
                                        <li>
                                            <button type="button" className="btn-icon"></button>
                                        </li>
                                        <li>
                                            <button type="button" className="btn-icon"></button>
                                        </li>
                                        <li>
                                            <button type="button" className="btn-icon"></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
