import React, { Component } from 'react';
import CardsComponent from '../Components/Cards/Cards';
import './Team.css';

class Team extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            card: {
                carousels: [
                    { src: 'https://dummyimage.com/1990x700/000/555' },
                    { src: 'https://dummyimage.com/1990x700/000/555' },
                    { src: 'https://dummyimage.com/1990x700/000/555' },
                    { src: 'https://dummyimage.com/1990x700/000/555' }
                ],
                title: 'Quem somos',
                texts: [
                    'Lorem ipsum dolor, consectetur adipiscing elit.',
                    'Maecenas ac justo tincidunt, consequat velit.'
                ],
                url: '/team'
            }
        };
    }

    render() {
        return (
            <div className="team">
                <CardsComponent context={this.state.card} />
            </div>
        )
    }
}

export default Team;
