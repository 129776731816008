import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import './Carousel.css';

class CarouselComponent extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleSelect = this.handleSelect.bind(this);
        
        this.state = {
            index: 0,
            delay: 5000,
            direction: null
        };
    }
  
    handleSelect(selectedIndex, e) {
        this.setState({
            index: selectedIndex,
            direction: e.direction,
        });
    }
  
    render() {
        const { index, direction, delay } = this.state;
        const { carousels } = this.props;
        let carousel_item = [];

        for (let i=0; i < carousels.length; i++ ) {
            carousel_item.push(
                <Carousel.Item key={i}>
                    <img alt={carousels[i].src}
                        className="d-block w-100"
                        src={carousels[i].src} />
                </Carousel.Item>
            )
        }
  
        return (
            <Carousel
                activeIndex={index}
                direction={direction}
                interval={delay}
                onSelect={this.handleSelect}>
                    {carousel_item}
            </Carousel>
        );
    }
}
  
export default CarouselComponent;
