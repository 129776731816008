import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import CarouselComponent from '../Carousel/Carousel'
import './Cards.css'

class CardsComponent extends Component {
    render() {
        const { context } = this.props;
        let card_text = [];
        let card_title = null;
        let card_url = null;
        let card_body = null;
        
        if (context.texts) {
            for (let i=0; i < context.texts.length; i++) {
                card_text.push(<Card.Text key={i}>{context.texts[i]}</Card.Text>);
            };
        }

        if (context.title) {
            card_title = <h3 className="card-title">{ context.title }</h3>;
        }

        if (context.url) {
            card_url = <a href={context.url} className="card-link">Saiba mais...</a>;
        }

        if (context.title || context.texts || context.url) {
            card_body = (
                <Card.Body>
                    { card_title }
                    { card_text }
                    { card_url } 
                </Card.Body>
            )
        }

        return (
            <Card>
                <CarouselComponent carousels={context.carousels} />
                {card_body}
            </Card>
        )
    }
}

export default CardsComponent;
