import React, { Component } from 'react';
import CardsComponent from '../Components/Cards/Cards';
import './Insurer.css';

class Insurer extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            card: {
                carousels: [
                    { src: 'https://dummyimage.com/1990x700/000/555' },
                    { src: 'https://dummyimage.com/1990x700/000/555' },
                    { src: 'https://dummyimage.com/1990x700/000/555' },
                    { src: 'https://dummyimage.com/1990x700/000/555' }
                ],
                id: 'aloha'
            }
        };
    }

    render() {
        return (
            <div className="insurer">
                <CardsComponent context={this.state.card} />
                <div className="container">
                    <a href="/insurer">Saiba mais</a>
                </div>
            </div>
        )
    }
}

export default Insurer;
