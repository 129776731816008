import React, { Component } from 'react';
import NavbarComponent from '../Components/Navbar/Navbar';
import CarouselComponent from '../Components/Carousel/Carousel';
import './Header.css';

class Header extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            carousels: [
                {
                    title: 'A CLÍNICA',
                    src: 'https://dummyimage.com/1990x700/000/555'
                },
                {
                    title: 'EQUIPE',
                    src: 'https://dummyimage.com/1990x700/000/555'
                },
                {
                    title: 'TRATAMENTOS',
                    src: 'https://dummyimage.com/1990x700/000/555'
                },
                {
                    title: 'CONTATO',
                    src: 'https://dummyimage.com/1990x700/000/555'
                }
            ]
        };
    }

    render() {
        return (
            <div className="header">
                <div className="container">
                    <div className="header-contact">
                        <p>Em construção...</p>
                        <p>Entre em contato: <a href="tel:55-21-3013-7654">(21) 3013 7654</a></p>
                    </div>
                </div>
                <NavbarComponent />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 carousel-wrapper">
                        <CarouselComponent
                            carousels={this.state.carousels}
                            />
                        </div>
                        <div className="col-sm-12 col-md-4 banner-text">
                            <h2>Lorem ipsum <strong>dolor</strong> sit amet.</h2>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}

export default Header;
