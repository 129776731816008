import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './Navbar.css'

class NavbarComponent extends Component {
    constructor(props) {
        super(props);

        this.closeNavbar = this.closeNavbar.bind(this);
        this.state = {
            isOpen: 'false'
        };
    }

    // TODO
    closeNavbar() {
        this.setState({
            isOpen: 'false'
        });
    }

    render() {
        return (
            <div className="container navbar-container">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="#home" id="logo">veincenter</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse isopen={this.state.isOpen} className="justify-content-end" id="responsive-navbar-nav">
                        <Nav>
                            <AnchorLink  offset="20" href='#clinic' onClick={this.closeNavbar}>A Clínica</AnchorLink>
                            <Nav.Link href="#team">Equipe</Nav.Link>
                            <NavDropdown title="Tratamentos" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#treatment1">Doenças venosas</NavDropdown.Item>
                                <NavDropdown.Item href="#treatment2">Doenças arteriais</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#treatment3">Fleboestética</NavDropdown.Item>
                                <NavDropdown.Item href="#treatment4">Crio Escleroterapia</NavDropdown.Item>
                                <NavDropdown.Item href="#treatment5">Escleroterapia Convencional</NavDropdown.Item>
                                <NavDropdown.Item href="#treatment6">Laser Transdermico</NavDropdown.Item>
                                <NavDropdown.Item href="#treatment7">Laser Endovenoso</NavDropdown.Item>
                                <NavDropdown.Item href="#treatment8">Radiofrequencia</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#treatment9">Miscelânia</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#contact">Quem Somos</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default NavbarComponent;
