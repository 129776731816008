import React, { Component } from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Team from './Team/Team';
import Clinic from './Clinic/Clinic';
import Treatments from './Treatments/Treatments';
import Insurer from './Insurer/Insurer';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <Team />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <Clinic />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <Treatments />
                        </div>
                    </div>
                </div>
                <Insurer />
                <div className="map">
                    <iframe className="iframe-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3676.7019721962547!2d-43.31130168461647!3d-22.850513185040494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x997c986f00e913%3A0xe9c5fd3cb351bdfe!2sPra%C3%A7a+Aquidauana+-+Vila+Cosmos%2C+Rio+de+Janeiro+-+RJ%2C+21210-024!5e0!3m2!1spt-BR!2sbr!4v1559415056229!5m2!1spt-BR!2sbr" frameBorder="0" allowFullScreen  title="iframe-map"/>
                </div>
                <Footer />
            </div>
        );
    }
}

export default App;
